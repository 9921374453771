import { useState } from "react"
import { FileUploader } from "./components/FileUploader"
import { ImageNavigator } from "../../shared/components/ImageNavigator"
import { useImageInfo } from "../../state/imageInfo"
import { useApiClient } from "../../state/apiClient"


export const ImageUploadPage = () => {
    const [ uploadedImages, setUploadedImages ] = useState([])
    const [ viewImageDetailsFor, setViewImageDetailsFor ] = useState()
    const [selectedImage, setSelectedImage] = useState(null)

    const { getOrFetchImageInfo, replaceImageInfo } = useImageInfo()
    const { apiClient } = useApiClient()

    const getThumbnails = () => {
        return uploadedImages?.map(imgInfo => ({
            src: imgInfo.localLink,
            imageId: imgInfo.imageId
        }))
    }

    const onImageChange = async (imageId) => {
        const imageInfo = await getOrFetchImageInfo({ imageId, apiClient })
        setSelectedImage(imageInfo)
    }

    const updateImageInfo = (imageInfo) => {
        replaceImageInfo(imageInfo)
        onImageChange(imageInfo._id)
    }

    return (
        <div className="flex flex-wrap h-full overflow-scroll">
            { !viewImageDetailsFor &&
            <div className="text-center w-full p-12 h-full">
                <>
                    <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
                        Upload Stuff Here
                    </h1>

                    <FileUploader
                        uploadedImages={uploadedImages}
                        setUploadedImages={setUploadedImages}
                    />
                    {uploadedImages.length > 0 &&
                    <div className="p-12 flex">
                        { uploadedImages.map((imgInfo, idx) => (
                            <div className="w-48 h-48 p-6   " key={idx}>
                                <button onClick={() => setViewImageDetailsFor(imgInfo.imageId)}>
                                <img src={imgInfo.localLink} />
                                </button>
                            </div>
                        ))}
                    </div>
                    }
                </>
            </div>
            }
            { viewImageDetailsFor && (
                <ImageNavigator
                    thumbnails={getThumbnails()}
                    onImageChange={onImageChange}
                    currentImage={selectedImage}
                    onExit={() => setViewImageDetailsFor(false)}
                    updateImageInfo={updateImageInfo}
                />
            )}
         </div>
    )
}