import React from "react";
import { useActiveDevice } from "../hooks/useActiveDevice";
import { useWaitingForImage } from "../hooks/useWaitingForImage";
import { THUMBNAIL_URL } from "../../../utility/environment";
import { LoadingSpinner } from "../../../layout/components/LoadingSpinner";
import { useImageInfo } from "../../../state/imageInfo";


const BASE_THUMB_URL = THUMBNAIL_URL
const THUMB_FOLDER_SIZE = '200';

export const AcquiredImageCarousel = ({ loading }) => {
    const { activeDevice } = useActiveDevice();
    const { activeImageId, images, setActiveImageId } = useImageInfo();
    const { waitingForImage } = useWaitingForImage();

    const imageList = Object.keys(images).map((id) => images[id]).sort((a, b) => a.date_completed > b.date_completed);
    const activeImage = images[activeImageId]

    const getImageLink = (image) => {
        const fileName = image?.file_name;
        return fileName ? `https://${BASE_THUMB_URL}/${THUMB_FOLDER_SIZE}/${fileName}` : '';
    }

    return (
        <div className="w-full flex flex-col overflow-y-auto">
            <div>
                { activeDevice?._id && !loading &&
                <span className="block text-sm font-medium text-blue-gray-900">
                    Last 10 Images:
                </span>
                }
            </div>
            { loading && (
                <LoadingSpinner />
            )}
            { !loading && (
                <div className="grid grid-rows-10 grid-cols-2 gap-1">
                    {imageList.map((image, i) =>
                        <div key={i} className={`border-8 ${(activeImage?._id === image?._id) ? "border-sky-300" : "border-white"}`}>
                            <button onClick={() => setActiveImageId(image._id)} className="w-full h-full object-cover" disabled={waitingForImage}>
                                <img src={getImageLink(image)} className={`${waitingForImage && "opacity-50"} h-full object-cover`} />
                            </button>
                        </div>
                    )}
                </div>
            )}
            { activeDevice?._id && !loading && imageList.length == 0 &&
                <span className="block text-sm text-gray-400">No images acquired yet.</span>
            }
        </div>
    );
}