import {
    Bars3BottomLeftIcon
} from '@heroicons/react/24/outline'

export const ContentArea = ({ setMobileMenuOpen, children }) => {
    return (
        <div className="flex basis-full flex-col overflow-hidden">
          <header className="w-full md:hidden">
            <div className="w-full relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primarycolor-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-between px-4 sm:px-6">
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1">
                { children }
            </main>
          </div>
        </div>
    )
}