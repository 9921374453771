import { useState } from "react";
import { useApiClient } from "../../../state/apiClient";
import { useDropzone } from 'react-dropzone'
import axios from "axios";
import { LoadingSpinner } from "../../../layout/components/LoadingSpinner";
import { PhotoIcon } from "@heroicons/react/20/solid";

const ACCEPTED_FILE_EXTS = ['jpg', 'jpeg', 'png'];

export const FileUploader = ({
    uploadedImages,
    setUploadedImages
}) => {
    const { apiClient } = useApiClient();
    const [ isUploading, setIsUploading ] = useState(false)

    const onDrop = async (acceptedFiles) => {
        setIsUploading(true)
        console.log('is uploading')
        console.log(acceptedFiles)

        const uploadPromises = []
        acceptedFiles.forEach((file) => {
            const fileType = (file?.name || '').split('.').pop();
            if (ACCEPTED_FILE_EXTS.includes(fileType)) {
                const uploadPromise = apiClient.getS3UploadUrl({
                    fileType,
                    contentType: file.type
                }).then(async ({ image, s3_link }) => {
                    await axios.put(s3_link, file, {
                        headers: {'Content-Type': file.type},
                    })
                    const link = URL.createObjectURL(file)
                    console.log({ link })
                    return { localLink: link, imageId: image }
                })
                uploadPromises.push(uploadPromise)
            }
        })
        const newUrlSrcs = await Promise.all(uploadPromises)

        setUploadedImages([...uploadedImages, ...newUrlSrcs])
        console.log('done uploading')
        setIsUploading(false)

    }
    const { getRootProps, getInputProps, /*isDragActive*/ } = useDropzone({onDrop})

    return (
        <button {...getRootProps()} className="w-full">
            <div
            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
            <input {...getInputProps()} disabled={isUploading} />
            { isUploading && (
                <>
                <LoadingSpinner />
                <span>Uploading...</span>
                </>
            )}
            { !isUploading && (
                <>
                <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-medium text-gray-900">Drop images here or click to add</span>
                </>
            )}
            </div>
        </button>
    )
}