import * as React from "react"
import { NavLink, useLocation } from "react-router-dom"
import linkMeta from "./linkMeta"
import { useAuthenticator } from '@aws-amplify/ui-react';
import { SmallIconButton } from "../components/Button";

export const Navbar = () => {
    const { signOut, user } = useAuthenticator((context) => [context.user]);

    console.log(user)

    const getLinkClasses = ({ isActive }) => {

        if (isActive) {
            return `${baseLinkClasses} ${activeLinkClasses}`
        }
        return `${baseLinkClasses} ${defaultLinkClasses}`
    }

    const location = useLocation();
    const listItems = linkMeta.map((linkInfo) => {
      return {
        ...linkInfo,
        active: location.pathname === linkInfo.to ? true : false
      }
    })

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    return (
      <div className="hidden w-28 overflow-y-auto bg-primarycolor-700 md:block">
        <div className="flex grow justify-between h-full w-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center bg-white p-2">
            <img
              className="h-8 w-auto"
              src="/integris-logo.png"
              alt="Integris Vet Verified"
            />
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-2">
              {user && listItems.map((link, i) =>
                <NavLink
                  key={i}
                  to={link.to}
                  end
                  className={getLinkClasses}
                  aria-current={link.active ? 'page' : undefined}
                >
                  <link.iconComponent
                    className={classNames(
                      link.active ? 'text-white' : 'text-primarycolor-300 group-hover:text-white',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2">{link.displayName}</span>
                </NavLink>
              )}
          </div>
          <div className="">
            {user &&
              <>
                <SmallIconButton onClick={signOut}>Logout</SmallIconButton>
              </>
            }
          </div>
        </div>
      </div>
    )
}

const defaultLinkClasses = `
  text-primarycolor-100 hover:bg-primarycolor-800 hover:text-white
`
const activeLinkClasses = `
  bg-primarycolor-800
  text-white
`
const baseLinkClasses = `
  group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium
`
