import axios from 'axios';

const BASE_URL = `https://${process.env.REACT_APP_API_HOSTNAME}`

export default class ApiClient {
    constructor(loggedInUser) {
        this.baseUrl = BASE_URL
        this.apiToken = loggedInUser?.signInUserSession.idToken.jwtToken
        this.cognitoUser = loggedInUser
        // axios.defaults.headers.common['Authorization'] = this.apiToken;
    }

    makeGetCall = async ({ url, params }) => {
        return axios.get(url, { params, headers: { Authorization: this.apiToken } })
    }

    makePostCall = async ({ url, params }) => {
        return axios.post(url, null, { params, headers: { Authorization: this.apiToken } })
    }

    getDeviceList = async () => {
        try {
            const url = `${BASE_URL}/devices`
            const rsp = await this.makeGetCall({ url })
            return rsp?.data?.devices || []
        } catch {
            return []
        }
    }

    getImageList = async (deviceId) => {
        try {
            const url = `${BASE_URL}/images`;
            const params = {
                device_id: deviceId,
                auth_user: this.cognitoUser.username
            };
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.images || []
        } catch {
            return []
        }
    }

    requestImage = async (deviceId, cameraType) => {
        try {
            const url = `${BASE_URL}/image-requests`;
            const params = {
                device_id: deviceId,
                camera_type: cameraType,
                auth_user: this.cognitoUser.username
            };
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data?.image || '';
        } catch {
            throw 'Error requesting image. Please try again';
        }
    }

    getImageInfo = async (imageId) => {
        try {
            const url = `${BASE_URL}/image`;
            const params = {
                id: imageId
            };
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.image || {}
        } catch {
            return {}
        }
    }

    getRoboflowProjects = async () => {
        try {
            const url = `${BASE_URL}/roboflow/projects`
            const rsp = await this.makeGetCall({ url })
            return rsp?.data?.projects || []
        } catch {
            return []
        }
    }

    getRoboflowProjectDetails = async (roboflowProject) => {
        try {
            const url = `${BASE_URL}/roboflow/project`
            const params = {
                roboflow_project: roboflowProject
            }
            const rsp = await this.makeGetCall({ url, params })
            return rsp?.data?.project || {}
        } catch {
            return {}
        }
    }

    addToRoboflowDataset = async ({ projectId, imageId }) => {
        try {
            const url = `${BASE_URL}/roboflow/projects/dataset-images`

            const params = {
                roboflow_project_id: projectId,
                image_id: imageId
            }
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data || {}
        } catch {
            return {}
        }
    }

    getS3UploadUrl = async ({ fileType, contentType }) => {
        try {
            const url = `${BASE_URL}/image-requests`;
            const params = {
                create_s3_upload_link: true,
                s3_upload_filetype: fileType,
                s3_content_type: contentType,
                auth_user: this.cognitoUser.username
            };
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data || {}
        } catch {
            throw 'Error requesting image upload link. Please try again';
        }
    }

    runRoboflowInference = async ({ modelId, imageId }) => {
        try {
            const url = `${BASE_URL}/roboflow/inference`;
            const params = {
                image_id: imageId,
                roboflow_model: modelId
            }
            const rsp = await this.makePostCall({ url, params })
            return rsp?.data?.results || {}
        } catch {
            throw 'Could not run model. Please try again';
        }
    }

}