import React, { useEffect, useState } from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import { AlertNotificationsHolder } from "./layout/components/AlertNotificationsHolder";
import { Navbar } from './layout/navigation/Navbar'
import { ImageAcquisitionPage } from './pages/image-acquisition/ImageAcquisitionPage'
import { NavigationContext, useNavigationContext } from './layout/navigation/useNavigationContext';
import { NavbarMobile } from "./layout/navigation/NavbarMobile";
import { HomePage } from "./pages/home/HomePage";
import { ContentArea } from "./layout/navigation/ContentArea";

import { useAuthenticator } from '@aws-amplify/ui-react'
import { useApiClient } from "./state/apiClient";
import ApiClient from "./utility/ApiClient";
import { ImageUploadPage } from "./pages/image-upload/ImageUploadPage";
import { ImageGalleryPage } from "./pages/image-gallery/ImageGalleryPage";


export const AuthenticatedLayout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);
  const { apiClient, setApiClient } = useApiClient()

  useEffect(() => {
    setApiClient(new ApiClient(user))
  }, [user])

  return (
    <div id="iat-app" className="flex h-full">

    { apiClient &&
        <NavigationContext.Provider value={useNavigationContext}>
            <Navbar />
            <NavbarMobile mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
            <ContentArea setMobileMenuOpen={setMobileMenuOpen}>
                <Routes>
                <Route path="/" exact={true} element={<HomePage />} />
                <Route path="/acquire-images" element={<ImageAcquisitionPage />} />
                <Route path="/upload-images" element={<ImageUploadPage />} />
                <Route path="/images/all" element={<ImageGalleryPage />} />
                </Routes>
            </ContentArea>
            <AlertNotificationsHolder />
        </NavigationContext.Provider>
    }
     </div>
  )
}

