import { useEffect } from "react"
import { useState } from "react"
import { ImageViewer } from "../../shared/components/ImageViewer/ImageViewer"
import { useImageInfo } from "../../state/imageInfo"
import { AcquiredImageCarousel } from "./components/AcquiredImageCarousel"
import { CameraInterface } from "./components/CameraInterface"
import { ActiveDeviceContext, useActiveDeviceContext } from "./hooks/useActiveDevice"
import { RoboflowProjectsContextProvider } from "./hooks/useRoboflowProjects"
import { WaitingForImageContext, useWaitingForImageContext, useWaitingForImage } from "./hooks/useWaitingForImage"

export const ImageAcquisitionPage = () => {
    const [imageListLoading, setImageListLoading] = useState(false);

    return (
        <RoboflowProjectsContextProvider>
        <ActiveDeviceContext.Provider value={useActiveDeviceContext()}>
            <WaitingForImageContext.Provider value={useWaitingForImageContext()}>
                <div className="h-full flex max-w-7xl">
                    <div className="h-full basis-64 flex-none flex flex-col px-4 pt-8 sm:px-6 lg:px-8 gap-y-6 bg-white border-r border-gray-200 bg-gray-50">
                        <CameraInterface setImageListLoading={setImageListLoading} />
                        <AcquiredImageCarousel loading={imageListLoading} />
                    </div>
                    <div className="flex-grow overflow-y-auto px-4 pt-8 sm:px-6 lg:px-8">
                    <ImageViewerWrapper />
                    </div>
                </div>
            </WaitingForImageContext.Provider>
        </ActiveDeviceContext.Provider>
        </RoboflowProjectsContextProvider>
    )
}

const ImageViewerWrapper = () => {
    const [activeImage, setActiveImage] = useState()
    const { waitingForImage, setWaitingForImage } = useWaitingForImage();
    const { activeImageId, images, replaceImageInfo } = useImageInfo();

    useEffect(() => {
        if (activeImageId && images) {
            setActiveImage(images[activeImageId])
        }
    }, [activeImageId, images])

    const updateImageInfo = (newImgInfo) => {
        replaceImageInfo(newImgInfo)
    }
    return (
        <ImageViewer
            image={activeImage}
            loadingImage={waitingForImage}
            setPerformingAction={setWaitingForImage}
            updateImageInfo={updateImageInfo}
        />
    )

}