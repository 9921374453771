import { ArrowUpOnSquareIcon, CameraIcon, HomeIcon, PhotoIcon } from "@heroicons/react/24/outline";

const linkMeta = [
    {
        to: '/',
        displayName: 'Home',
        iconComponent: HomeIcon,
    },
    {
        to: '/acquire-images',
        displayName: 'Acquire',
        iconComponent: CameraIcon
    },
    {
        to: '/upload-images',
        displayName: 'Upload',
        iconComponent: ArrowUpOnSquareIcon
    },
    {
        to: '/images/all',
        displayName: 'All Images',
        iconComponent: PhotoIcon
    }
];

export default linkMeta;