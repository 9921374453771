import { useEffect, useState } from "react";
import { ImageNavigator } from "../../shared/components/ImageNavigator";
import { useApiClient } from "../../state/apiClient"
import { THUMBNAIL_URL } from "../../utility/environment";

const BASE_THUMB_URL = THUMBNAIL_URL
const THUMB_FOLDER_SIZE = '200';

export const ImageGalleryPage = () => {
    const [images, setImages] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    const { apiClient } = useApiClient();

    useEffect(() => {
        const fetchImages = async () => {
            const images = await apiClient.getImageList()
            setImages(images)
        }
        fetchImages()
    }, [])

    const thumbnailSrc = (img) => {
        const fileName = img?.file_name;
        return fileName ? `https://${BASE_THUMB_URL}/${THUMB_FOLDER_SIZE}/${fileName}` : '';
    }

    const thumbnails = images.map(img => ({
        src: thumbnailSrc(img),
        imageId: img._id
    }))

    const updateImageInfo = (updatedImg) => {
        console.log({ updatedImg })
        const newImages = images.map(img => img._id === updatedImg._id ? updatedImg : img)
        console.log({ newImages })
        setImages(newImages)
    }

    const onImageChange = (imageId) => {
        const newImg = images.find(img => img._id === imageId)
        setSelectedImage(newImg)
    }

    useEffect(() => {
        if(selectedImage) {
            setSelectedImage(images.find(img => img._id === selectedImage._id))
        }
    }, [images])

    return (
        <div className="flex flex-wrap h-full overflow-scroll">
            {!selectedImage && images.map((img, idx) => (
                <div className="p-6 shrink-0" key={idx}>
                    <button onClick={() => setSelectedImage(img)} >
                        <img src={thumbnailSrc(img)} />
                    </button>
                </div>
            ))}
            {selectedImage &&
                <ImageNavigator
                    thumbnails={thumbnails}
                    onImageChange={onImageChange}
                    currentImage={selectedImage}
                    onExit={() => setSelectedImage(null)}
                    updateImageInfo={updateImageInfo}
                />
            }

        </div>

    )
}